import { combineReducers } from "redux";
import UserReducer from './UserReducer';
import UnitReducer from './UnitReducer';
import BLCarReducer from './BLCarReducer';
import PerList from './PerReducer';

export default combineReducers({
  //User
  UserList: UserReducer,
  PerList: PerList,
  //Unit
  UnitList: UnitReducer,
  //BLCar
  BLCarList: BLCarReducer
});
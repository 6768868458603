export default {
  //User
  LOAD_USER_LIST: 'LOAD_USER_LIST',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  LOAD_PER_LIST: 'LOAD_PER_LIST',
  ADD_PER: 'ADD_PER',
  EDIT_PER: 'EDIT_PER',
  DELETE_PER_IDS: 'DELETE_PER_IDS',
  //Unit
  LOAD_UNIT_LIST: 'LOAD_UNIT_LIST',
  ADD_UNIT: 'ADD_UNIT',
  EDIT_UNIT: 'EDIT_UNIT',
  //BLCar
  LOAD_BLCAR_LIST: 'LOAD_BLCAR_LIST',
  ADD_BLCAR: 'ADD_BLCAR',
  EDIT_BLCAR: 'EDIT_BLCAR'

}
import React, { Component } from 'react'
import { Breadcrumb, Table, Button, Modal, message, Avatar, Popconfirm, Input } from 'antd';
import { Link } from 'react-router-dom';
import { LoadUnitActionAsync } from '../../../Action/UnitAction';
import AddUnit from './AddUnit';
import EditUnit from './EditUnit';
import service from '../../../Service';
import store from '../../../store';

class UnitMgr extends Component {
  state = {
    showAddUnitDialog: false,  // 显示要添加用户的对话框
    showEditUnitDialog: false, // 显示修改的对话框
    editUnitRow: null,         // 当前编辑的用户信息 
    unsubscribe: null,
    selectRowKeys: [],
    unitlist: store.getState().UnitList.list,
    total: 0,
    params: {_page: 1, _limit: 1000, siteName: "Tampines Dorm"},
    columns: [{
      key: 'id',
      title: 'Id',
      dataIndex: 'id'
    },{
      key: 'blockNo',
      title: 'Block No.',
      dataIndex: 'blockNo'
    },{
      key: 'unitNo',
      title: 'Unit No.',
      dataIndex: 'unitNo'
    }, {
      key: 'description',
      title: 'Description',
      dataIndex: 'description'
    }, {
      key: 'postalCode',
      title: 'Postal Code',
      dataIndex: 'postalCode'
    },
    ]
  }

  deleteUnit = (id) => {
    service
      .deleteResidentUnits([id])
      .then(res => {
        console.log("herehere");
        console.log(res.data);
        
        
        store.dispatch(LoadUnitActionAsync(this.state.params));
        message.info('Delete Successfully');
        let newSelectRowKeys = this.state.selectRowKeys.filter(item => item !== id);
        this.setState({selectRowKeys: newSelectRowKeys});
      })
      .catch(e => {
        console.log(e);
        message.error('Delete Failed!');
      });
  }
  unitListChange = () => {
    const UnitList = store.getState().UnitList;
    this.setState({unitlist: UnitList.list, total: UnitList.total});
  }
  componentDidMount() {
    // 发送ajax请求到后台，获取当前用户的列表数据
    // service.loadUnitList()
    // .then(res => {
    //   this.setState({unitlist: res.data});
    // })
    store.dispatch(LoadUnitActionAsync(this.state.params));
    const unsubscribe = store.subscribe(this.unitListChange);
    this.setState({unsubscribe: unsubscribe});
  }

  componentWillUnmount() {
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  changePage = (page, pageSize, q="") => {
    if(!!q) {
      q = this.state.params.q;
    }
    this.setState(preState=> {
      return {...preState, ...{params: {_page: page, _limit: pageSize, q}}}
    }, ()=> {
      store.dispatch(LoadUnitActionAsync(this.state.params));
    });
  }

  hideAddUnitDialog = () => {
    this.setState({showAddUnitDialog: false});
  }

  hideEditUnitDialog = () => {
    this.setState({showEditUnitDialog: false});
  }

  handleDelete = () => {
    if(this.state.selectRowKeys.length <= 0) {
      message.warn('Please select one to delete!');
      return;
    }
    // 拿到所有要删除的数据
    Modal.confirm({
      title: 'Are you sure to delete?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        // console.log(this.state.selectRowKeys);
        service
          .deleteResidentUnits(this.state.selectRowKeys)
          .then(res => {
            console.log("herehereherehere");
          console.log(this.state.selectRowKeys);
            console.log(res.data);
            store.dispatch(LoadUnitActionAsync(this.state.params));
            message.info('Delete Successfully!');
            this.setState({selectRowKeys: []});
          })
          .catch(e => {
            console.log(e);
            message.error('Delete Failed!');
          })
      }
    })
  }

  handleEdit = () => {
    if(this.state.selectRowKeys.length !== 1) {
      message.error('Please select one to edit!');
      return;
    }

    // 拿到要进行编辑的数据
    const unitId = this.state.selectRowKeys[0]
    let editUnit = store.getState().UnitList.list.find(item => item.id === unitId);
    console.log(editUnit);
    this.setState({
      showEditUnitDialog: true,
      editUnitRow: editUnit
    })
  }
 
  buttonStyle = {margin: '5px'};

  render () {
    let { selectRowKeys } = this.state;
    let userRowSelection = {
      selectedRowKeys: selectRowKeys,
      onChange: (selectedRowKeys) => {
        console.log(selectedRowKeys);
        this.setState({selectRowKeys: selectedRowKeys})
      }
    }
    return (
      <div className="admin-usermgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/block_unit">Blocks and Units</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        <Button onClick={()=> this.setState({showAddUnitDialog: true})} style={this.buttonStyle} type="primary">Add</Button>
        <Button onClick={ this.handleDelete } style={this.buttonStyle} type="danger">Delete</Button>
        <Button onClick={ this.handleEdit } style={this.buttonStyle} type="primary">Update</Button>
       
        <Table
          bordered
          style={{backgroundColor: '#FEFEFE'}}
          dataSource={this.state.unitlist}
          columns={this.state.columns}
          rowSelection={userRowSelection}
          rowKey="id"
          pagination = {{total: this.state.total, pageSize: 1000, defaultCurrent: 1, onChange: this.changePage}}
        ></Table>
        <AddUnit close={this.hideAddUnitDialog} visible={this.state.showAddUnitDialog}></AddUnit>
        <EditUnit data={this.state.editUnitRow} close={this.hideEditUnitDialog} visible={this.state.showEditUnitDialog}></EditUnit>
      </div>
    )
  }
}

export default UnitMgr
import React, { Component } from 'react'
import { Form, Input, Icon } from 'antd';

class EditUnitFrm extends Component {
  componentDidMount() {
    this.props.form.setFieldsValue({
      blockNo: this.props.data.blockNo,
      unitNo: this.props.data.unitNo,
      description: this.props.data.description,
      postalCode: this.props.data.postalCode
    });
  }
  render () {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form 
        layout="horizontal"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
      >
        {/* <Form.Item label="Email">
          {getFieldDecorator('userEmail', {
            rules: [
              {
                type: 'email',
                message: 'Please enter correct Email!',
              },
              {
                required: true,
                message: 'Please enter Email!',
              }
            ],
          })(
            <Input prefix={<Icon type="mail"></Icon>} 
              placeholder="Email"
            />
          )}
        </Form.Item> */}
        <Form.Item label="Block No.">
          {getFieldDecorator('blockNo', {
            rules: [
              {
                required: true,
                message: 'Please enter Block No.!',
              }
            ],
          })(
            <Input prefix={<Icon type="bank"></Icon>} 
              placeholder="Block No."
            />
          )}
        </Form.Item>
       
        <Form.Item label="Unit No.">
          {getFieldDecorator('unitNo', {
            rules: [
              {
                required: true,
                message: 'Please enter Unit No.!',
              }
            ],
          })(
            <Input prefix={<Icon type="book"></Icon>}
            placeholder="Unit No."
          />
          )}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [
              {
                required: false,
                message: 'Please enter description!',
              }
            ],
          })(
            <Input prefix={<Icon type="edit"></Icon>} 
              placeholder="Description"
            />
          )}
        </Form.Item>
        <Form.Item label="Postal Code">
          {getFieldDecorator('postalCode', {
            rules: [
              {
                pattern: /\d{6}/gi,
                message: 'Please enter 6 digits Postal Code!',
              }
            ],
          })(
            <Input prefix={<Icon type="pushpin"></Icon>} 
              placeholder="Postal Code"
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

export default EditUnitFrm

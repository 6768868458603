import React, { Component } from 'react'
import { Modal, Form, message } from 'antd';
import AddUserFrm from './AddUserFrm';
import store from '../../../store';
import { AddUserActionAsync } from '../../../Action/UserAction';
import { getLoginUserName, getLoginUserEmail } from '../../../Common/Auth';

const AddUserFrmComponent = Form.create({name: 'adduser_frm'})(AddUserFrm);
class AddUser extends Component {
  userAddFrm = null;
  state = { fileList: []}
  handleSubmit = () => {
    this.userAddFrm.validateFields((err, data) => {
      // console.log(data);
      if(err) {
        return;
      }
      data.siteName = "Tampines Dorm";
      data.userRole = "SiteAdmin";
      // getLoginUserName()
      getLoginUserEmail()
        .then(res => {
          data.createdBy = res;
        })
      // 给上传的头像添加服务器前缀。
      data.avatar = process.env.REACT_APP_BASEURL + data.avatar;
      store
        .dispatch(AddUserActionAsync(data))
        .then(res => {
          message.info('User Added Successfully!');
          // 重置添加对话框和关闭对话框。
          this.hanldeCloseModal();
          this.refreshPage();
        })
        .catch((e)=> {
          message.error('Add User Failed! Please change email address and re-try!');
          console.log(e);
        }); 
    })
  }
  refreshPage = (params = {}) => {
    window.location.reload(false);
  };

  hanldeCloseModal = () => {
    // 清空所有添加的表单。
    this.userAddFrm.resetFields();
    // 清理上传文件
    this.setState({fileList: []});
    this.props.close();
  }
  changeFileList = (fileList) => {
    this.setState({fileList});
  }

  render () {
    return (
      <Modal
        title="Add Admin User Info"
        okText="Confirm"
        cancelText="Cancel"
        visible={this.props.visible}
        onCancel={this.hanldeCloseModal}
        onOk={this.handleSubmit}
      >
        <AddUserFrmComponent
          ref={frm => this.userAddFrm = frm }
          fileList={this.state.fileList}
          changeFileList={this.changeFileList}
        ></AddUserFrmComponent>
      </Modal>
    )
  }
}

export default AddUser
import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import Top from '../../Components/Top';
import MenuAtact from '../../Components/MenuAtact';
// import MenuBar from '../../Components/MenuBar';
// import UserMgr from '../Admin/UserMgr';
import RoleMgr from '../Admin/RoleMgr';
import PerMgr from '../Admin/PerMgr';
import axios from 'axios';
import { getLoginTocken } from '../../Common/Auth';
import AuthRoute from '../../Components/AuthRoute';
import GoodsMgr from '../GoodsMgr';
import LiveVisitor from '../LiveVisitor';
import VisitorEntry from '../VisitorEntry';
import GuardVisitor from '../GuardVisitor';
import ATACTSetting from '../ATACTSetting';
import UserMgr from '../Admin/TeamMgr';
import ProfileMgr from '../Admin/ProfileMgr';
import UnitMgr from '../Admin/UnitMgr';
import BLCarMgr from '../Admin/BLCarMgr';

const { Header, Footer, Sider, Content } = Layout;

class Home extends Component {
  constructor(props) {
    super(props);
    // 设置当前用户ajax请求的token
    axios.defaults.headers['Authorization'] = getLoginTocken();
    sessionStorage.removeItem('LOGIN_USER_PER');
  }
  
  render () {
    const {match} = this.props;
    return (
      <Layout style={{height: '100vh'}}>
        <Header style={{color: '#fff', padding: '0 15px'}}>
          <Top history={this.props.history}></Top>
        </Header>
        <Layout style={{overflow: 'scroll'}}>
          <Sider style={{backgroundColor: '#FFF'}}>
            <MenuAtact history={this.props.history}></MenuAtact>
          </Sider>
          <Content style={{padding: '15px'}}>
            <Switch>
              {/* <AuthRoute per={1570872984243} path={`${match.path}/user_mgr`} component={UserMgr}></AuthRoute>
              <AuthRoute per={1570873025381} path={`${match.path}/role_mgr`} component={RoleMgr}></AuthRoute>
              <AuthRoute per={1570873044424} path={`${match.path}/per_mgr`} component={PerMgr}></AuthRoute> */}
              <Route path={`${match.path}/goods_mgr`} component={GoodsMgr}></Route>
              <Route path={`${match.path}/visitor_entry`} component={VisitorEntry}></Route>
              <Route path={`${match.path}/live_visitor`} component={LiveVisitor}></Route>
              <Route path={`${match.path}/guard_visitor`} component={GuardVisitor}></Route>
              <Route path={`${match.path}/block_unit`} component={UnitMgr}></Route>
              <Route path={`${match.path}/atact_setting`} component={ATACTSetting}></Route>
              <AuthRoute per={1570873044424} path={`${match.path}/team_mgr`} component={UserMgr}></AuthRoute>
              <Route path={`${match.path}/blacklist_car`} component={BLCarMgr}></Route>
              <Route path={`${match.path}/profile_mgr`} component={ProfileMgr}></Route>
              <Route render=
                {
                ()=>
                  <h3>Welcome to A TACT Visitor Management System</h3>
                 
                }>  
              </Route>
            </Switch>
          </Content>
        </Layout>
        <Footer style={{backgroundColor: 'silver', height: '24px', padding: '0', lineHeight: '24px'}}>Powered by atact.org</Footer>
      </Layout>
    )
  }
}

export default Home
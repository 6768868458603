import React, { Component } from 'react'
import { Form, Input, Icon, Upload, Button } from 'antd';
import { getLoginTocken } from '../../../Common/Auth';
class AddBLCarFrm extends Component {
  handleChangeAvatar = (e) => {
    this.props.changeFileList(e.fileList);
    if(e.file.response) {
      console.log(e.file.response);
      return e.file.response.img;
    }
    return '';
  }
  render () {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form 
        layout="horizontal"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
      >
      {/* <Form.Item label="用户头像">
          {getFieldDecorator('avatar', {
            getValueFromEvent: this.handleChangeAvatar,
            rules: [
              {
                required: true,
                message: '请上传图片!',
              }
            ],
          })(
            <Upload
              accept="image/*"
              action="/per/upload"
              headers={{Authorization: getLoginTocken()}}
              name="imgF"
              listType="picture"
              onChange={this.handleChangeAvatar}
              fileList={this.props.fileList}
            >
              <Button>上传头像</Button>
            </Upload>
          )}
        </Form.Item> */}
        <Form.Item label="Car No.">
          {getFieldDecorator('carPlateNo', {
            rules: [
              {
                required: true,
                message: 'Please enter Car Plate No.!',
              }
            ],
          })(
            <Input prefix={<Icon type="bank"></Icon>} 
              placeholder="Car Plate No."
            />
          )}
        </Form.Item>
        <Form.Item label="Reason">
          {getFieldDecorator('reason', {
            rules: [
              {
                required: true,
                message: 'Please enter reason!',
              }
            ],
          })(
            <Input prefix={<Icon type="book"></Icon>}
              placeholder="Reason"
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

export default AddBLCarFrm
import ActionTypes from './ActionTypes';
import service from '../Service';

export function LoadBLCarAction(payload) {
  return {
    type: ActionTypes.LOAD_BLCAR_LIST,
    payload
  }
}

// redux-thunk用法： 
export function LoadBLCarActionAsync(params) {
  return dispatch => {
    return service.loadBLCars(params)
      .then(res => {
        console.log("mmmmm");
        console.log(params);
        console.log(res.data.resp_records);
        
        dispatch(LoadBLCarAction({list: res.data.resp_records, total: parseInt(res.headers['x-total-count'])}));
      })
  }
}

export function AddBLCarAction(payload) {
  console.log(payload);
  console.log("hahahha");
  return {
    type: ActionTypes.ADD_BLCAR,
    payload
  }
}
export function AddBLCarActionAsync(payload) {
  return dispatch => {
    return service.addBLCarPlate(payload)
      .then(res => {
        console.log("AddBLCarAction Resp:");
        console.log(res.data);
        
        
        dispatch(AddBLCarAction(payload));
      })
  }
}

export function EditBLCarActionAsync(payload) {
  return dispatch => {
    return service.updateBLCarPlate(payload)
      .then(res => {
        console.log("EditBLCarAction Resp");
        console.log(res.data);
        dispatch(EditBLCarAction(payload));
      })
  }
}

export function EditBLCarAction(payload) {
  return {
    type: ActionTypes.EDIT_BLCAR,
    payload
  }
}
import Service from "../Service";

// 当前登录用户的sessionStorage的key
const APP_LOGIN_USER = 'APP_LOGIN_USER';

/**
 * 校验当前用户是否已经登录
 * @return { Boolean } 如果已经登录： true， 否则返回false
 */
export function AuthLogin() {
  // sessionStorage 存储当前的登录的信息。
  let loginUserStr = sessionStorage.getItem(APP_LOGIN_USER);
  if(loginUserStr) {
    return true
  }
  return false;
}

/**
 * 存储当前用户登录的信息到本地存储
 * @param {Object} user 
 * @return undefined
 */
export function SaveLoginUserInfo(user) {
  sessionStorage.setItem(APP_LOGIN_USER, JSON.stringify(user));
}

/**
 * 获取当前用户登录的信息
 * @return {Object} user
 */
export function GetLoginUserInfo() {
  let userStr = sessionStorage.getItem(APP_LOGIN_USER);
  if(userStr) {
    return JSON.parse(userStr);
  } 
  return null;
}

/**
 * 当前用户登出，处理后续的相关的信息
 * @return {Object} user
 */
export function Logout() {
 sessionStorage.clear(); // 清空所有的用户登录的相关信息
}

/**
 * 保存用户登录后台的tocken信息
 * @param {String} tocken 请求的身份
 */
export function saveLoginTocken(tocken) {
  sessionStorage.setItem('Authorization', tocken);
}

/**
 * 获取用户登录后台的tocken信息
 * @return {String} tocken 请求的身份
 */
export function getLoginTocken(tocken) {
  return sessionStorage.getItem('Authorization');
}

/** 
 * 获取当前登录用户的所有权限
 * @return {Promise} 对象内部返回当前登录用户的所有权限
*/
export function getLoginUserAllPer() {
  // 拿到当前登录用户的id
  let userId = GetLoginUserInfo().id;
  console.log(userId);
  //  先从缓存中获取当前登录用户的所有权限。如果有直接返回
  let loginUserPerStr = sessionStorage.getItem('LOGIN_USER_PER');
  console.log(loginUserPerStr);
  if(loginUserPerStr) {
    return Promise.resolve(JSON.parse(loginUserPerStr));
  }
  // 如果没有 才发送ajax请求获取当前登录用户的所有权限
  let param = {
    id : ''
  }
  param.id = userId.toString();
  return Service.loadUserAllPer(param)
    .then(res => {
      console.log("Here:");
      console.log(res.data);
      console.log("Here!");
      let perStr = res.data.resp_records[0].userRole
      console.log(perStr)
      sessionStorage.setItem('LOGIN_USER_PER', JSON.stringify(perStr));
      return perStr;
    })
}

export function getLoginUserName() {
  // 拿到当前登录用户的id
  let userId = GetLoginUserInfo().id;
  console.log(userId);
  //  先从缓存中获取当前登录用户的所有权限。如果有直接返回
  let loginUserNameStr = sessionStorage.getItem('LOGIN_USER_NAME');
  console.log(loginUserNameStr);
  if(loginUserNameStr) {
    return Promise.resolve(JSON.parse(loginUserNameStr));
  }
  // 如果没有 才发送ajax请求获取当前登录用户的所有权限
  let param = {
    id : ''
  }
  param.id = userId.toString();
  return Service.loadUserAllPer(param)
    .then(res => {
      console.log("Here:");
      console.log(res.data);
      console.log("Here!");
      let nameStr = res.data.resp_records[0].userName
      console.log(nameStr)
      sessionStorage.setItem('LOGIN_USER_NAME', JSON.stringify(nameStr));
      return nameStr;
    })
}

export function getLoginUserEmail() {
  // 拿到当前登录用户的id
  let userId = GetLoginUserInfo().id;
  console.log(userId);
  //  先从缓存中获取当前登录用户的所有权限。如果有直接返回
  let loginUserEmailStr = sessionStorage.getItem('LOGIN_USER_EMAIL');
  console.log(loginUserEmailStr);
  if(loginUserEmailStr) {
    return Promise.resolve(JSON.parse(loginUserEmailStr));
  }
  // 如果没有 才发送ajax请求获取当前登录用户的所有权限
  let param = {
    id : ''
  }
  param.id = userId.toString();
  return Service.loadUserAllPer(param)
    .then(res => {
      console.log("Here:");
      console.log(res.data);
      console.log("Here!");
      let emailStr = res.data.resp_records[0].userEmail
      console.log(emailStr)
      sessionStorage.setItem('LOGIN_USER_EMAIL', JSON.stringify(emailStr));
      return emailStr;
    })
}
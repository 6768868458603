import axios from 'axios';

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  // 在此判断当前用户是否拥有请求此地址的权限，如果有那么放行，如果没有那么需要
  // 截断此请求
  // 如何判断当前用户是否拥有此请求权限
  console.log(config.url); // 你要请求的url地址。
  // return Promise.reject({msg: '没有权限'});
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

export default {
  userLogin(data) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/loginCenter.php', data);
  },
  loadUserList(params) {
    params = {...params, ...{_sort: 'id', _order: 'desc'}}
    return axios.post('https://backend.atact.org/APP/VMSWeb//userMgr/loadAdminUserList.php', params);
  },
  loadSuperAdminUserSelf(params) {
    params = {...params, ...{_sort: 'id', _order: 'desc'}}
    return axios.post('https://backend.atact.org/APP/VMSWeb//userMgr/loadSuperAdminUserSelf.php', params);
  },
  addUser(data) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/userMgr/addAdminUser.php', data);
  },
  deleteUser(ids) {
    return Promise.all(ids.map(id => {
      return axios.post('https://backend.atact.org/APP/VMSWeb/userMgr/deleteAdminUser.php', {"id": id});
    }));
  },
  updateUser(params) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/userMgr/updateAdminUser.php', params);
  },
  loadRoleList(params) {
    return axios.get('/per/role', {params});
  },
  deleteRoles(ids) {
    return Promise.all(ids.map(id => axios.delete(`/per/role/${id}`)));
  },
  addRole(role) {
    return axios.post('/per/role', role);
  },
  saveRole(role) {
    return axios.put(`/per/role/${role.id}`, role);
  },
  // 权限相关方法
  loadPerList(params) {
    return axios.get('/per/permission', {params});
  },
  // 添加权限的方法
  addPer(per) {
    return axios.post('/per/permission', per);
  },
  // 修改权限
  editPer(per) {
    return axios.put(`/per/permission/${per.id}`, per);
  },
  // 删除权限
  deletePer(ids) {
    return Promise.all(ids.map(id => {
      return axios.delete(`/per/permission/${id}`);
    }));
  },
  // 加载所有的角色信息
  loadAllRoles() {
    return axios.get('/per/role');
  },
  // 加载用户关联的角色信息
  loadUserRoles(userId) {
    return axios.get('/per/user_role', { params: { userId}});
  },
  // 给用户设置关联的角色信息
  addUserRole(userRole) {
    return axios.post('/per/user_role', userRole);
  },
  // 删除用户和角色的关联
  deleteUserRole(id) {
    return axios.delete(`/per/user_role/${id}`);
  },
  // 加载所有的权限数据
  loadAllPer() {
    return axios.get('/per/permission');
  },
  loadRolePer(roleId) {
    return axios.get('/per/role_permission', {params: {roleId}});
  },
  // 添加角色关联权限
  addRolePer(rolePer) {
    return axios.post('/per/role_permission', rolePer);
  },
  // 去掉角色和权限关联
  deleteRolePer(rolePerId) {
    return axios.delete(`/per/role_permission/${rolePerId}`);
  },
  // 加载用户的所有关联的用户权限
  loadUserPer(userId) {
    return axios.get('/per/user_permission', {params: {userId}});
  },
  // 添加用户关联的权限
  addUserPer(userPer) {
    return axios.post('/per/user_permission', userPer);
  },
  // 删除用户关联权限
  deleteUserPer(id) {
    return axios.delete(`/per/user_permission/${id}`);
  },
  // 加载用户的所有权限
  loadUserAllPer(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/userMgr/getUserPer.php', param);
  },
  // A TACT Get Records
  loadGuardVisitors(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getVisitorRecords.php', param);
  },
  // A TACT Get Records
  loadLiveRecords(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getLiveRecords.php', param);
  },
  // A TACT Get Records
  loadVisitorEntryRecords(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getVisitorEntryRecords.php', param);
  },
   // A TACT Get Records with Dates
  loadLiveRecordsWithDates(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getLiveRecordsWithDates.php', param);
  },
    // A TACT Get Records with Dates
  loadVisitorEntryRecordsWithDates(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getVisitorEntryRecordsWithDates.php', param);
  },
  // A TACT Get Resident Unit
  loadResidentUnits(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getBlockUnit.php', param);
  },
   // A TACT Add Resident Unit
  addResidentUnits(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/addResidentUnit.php', param);
  },
  // A TACT Delete Resident Unit
  deleteResidentUnits(ids) {
    return Promise.all(ids.map(id => {
      return axios.post('https://backend.atact.org/APP/VMSWeb/deleteResidentUnit.php', {"id": id});
    }));
  },
  // A TACT Update Resident Unit
  updateResidentUnits(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/updateResidentUnit.php', param);
  },
  // A TACT Get Blacklist CarPlate
  loadBLCars(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getBLCar.php', param);
  },
  // A TACT Add Blacklist CarPlate
  addBLCarPlate(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/addBLCar.php', param);
  },
  // A TACT Delete Blacklist CarPlate
  deleteBLCarPlate(ids) {
    return Promise.all(ids.map(id => {
      return axios.post('https://backend.atact.org/APP/VMSWeb/deleteBLCar.php', {"id": id});
  }));
    },
  // A TACT Update Blacklist CarPlate
  updateBLCarPlate(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/updateBLCar.php', param);
  },
  // A TACT Get Default Period
  getDefaultPeriod(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/getDefaultPeriod.php', param);
  },
   // A TACT Update Default Period
   updateDefaultPeriod(param) {
    return axios.post('https://backend.atact.org/APP/VMSWeb/updateDefaultPeriod.php', param);
  }
}
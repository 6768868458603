import React, { Component } from 'react'
import { Breadcrumb, Table, Button, Modal, message, Avatar, Popconfirm, Input } from 'antd';
import { Link } from 'react-router-dom';
import { LoadBLCarActionAsync } from '../../../Action/BLCarAction';
import AddBLCar from './AddBLCar';
import EditBLCar from './EditBLCar';
import service from '../../../Service';
import store from '../../../store';

class BLCarMgr extends Component {
  state = {
    showAddBLCarDialog: false,  // 显示要添加用户的对话框
    showEditBLCarDialog: false, // 显示修改的对话框
    editBLCarRow: null,         // 当前编辑的用户信息 
    unsubscribe: null,
    selectRowKeys: [],
    blCarlist: store.getState().BLCarList.list,
    total: 0,
    params: {_page: 1, _limit: 1000, siteName: "Tampines Dorm"},
    columns: [{
      key: 'id',
      title: 'Id',
      dataIndex: 'id'
    },{
      key: 'carPlateNo',
      title: 'Car Plate No.',
      dataIndex: 'carPlateNo'
    },{
      key: 'createdBy',
      title: 'Created By',
      dataIndex: 'createdBy'
    }, {
      key: 'reason',
      title: 'Reason',
      dataIndex: 'reason'
    }, {
      key: 'updateDate',
      title: 'Updated Date',
      dataIndex: 'updateDate'
    },
    {
      key: 'updateTime',
      title: 'Updated Time',
      dataIndex: 'updateTime'
    },
    {
      key: 'logs',
      title: 'Logs',
      dataIndex: 'logs'
    },
    ]
  }

  deleteBLCar = (id) => {
    service
      .deleteBLCarPlate([id])
      .then(res => {
        console.log("herehere");
        console.log(res.data);
        
        
        store.dispatch(LoadBLCarActionAsync(this.state.params));
        message.info('Delete Successfully');
        let newSelectRowKeys = this.state.selectRowKeys.filter(item => item !== id);
        this.setState({selectRowKeys: newSelectRowKeys});
      })
      .catch(e => {
        console.log(e);
        message.error('Delete Failed!');
      });
  }
  blCarListChange = () => {
    const BLCarList = store.getState().BLCarList;
    this.setState({blCarlist: BLCarList.list, total: BLCarList.total});
  }
  componentDidMount() {
    // 发送ajax请求到后台，获取当前用户的列表数据
    // service.loadBLCarList()
    // .then(res => {
    //   this.setState({blCarlist: res.data});
    // })
    store.dispatch(LoadBLCarActionAsync(this.state.params));
    const unsubscribe = store.subscribe(this.blCarListChange);
    this.setState({unsubscribe: unsubscribe});
  }

  componentWillUnmount() {
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  changePage = (page, pageSize, q="") => {
    if(!!q) {
      q = this.state.params.q;
    }
    this.setState(preState=> {
      return {...preState, ...{params: {_page: page, _limit: pageSize, q}}}
    }, ()=> {
      store.dispatch(LoadBLCarActionAsync(this.state.params));
    });
  }

  hideAddBLCarDialog = () => {
    this.setState({showAddBLCarDialog: false});
  }

  hideEditBLCarDialog = () => {
    this.setState({showEditBLCarDialog: false});
  }

  handleDelete = () => {
    if(this.state.selectRowKeys.length <= 0) {
      message.warn('Please select one to delete!');
      return;
    }
    // 拿到所有要删除的数据
    Modal.confirm({
      title: 'Are you sure to delete?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        // console.log(this.state.selectRowKeys);
        service
          .deleteBLCarPlate(this.state.selectRowKeys)
          .then(res => {
            console.log("herehereherehere");
          console.log(this.state.selectRowKeys);
            console.log(res.data);
            store.dispatch(LoadBLCarActionAsync(this.state.params));
            message.info('Delete Successfully!');
            this.setState({selectRowKeys: []});
          })
          .catch(e => {
            console.log(e);
            message.error('Delete Failed!');
          })
      }
    })
  }

  handleEdit = () => {
    if(this.state.selectRowKeys.length !== 1) {
      message.error('Please select one to edit!');
      return;
    }

    // 拿到要进行编辑的数据
    const blCarId = this.state.selectRowKeys[0]
    let editBLCar = store.getState().BLCarList.list.find(item => item.id === blCarId);
    console.log(editBLCar);
    this.setState({
      showEditBLCarDialog: true,
      editBLCarRow: editBLCar
    })
  }
 
  buttonStyle = {margin: '5px'};

  render () {
    let { selectRowKeys } = this.state;
    let userRowSelection = {
      selectedRowKeys: selectRowKeys,
      onChange: (selectedRowKeys) => {
        console.log(selectedRowKeys);
        this.setState({selectRowKeys: selectedRowKeys})
      }
    }
    return (
      <div className="admin-usermgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/blacklist_car">Black List (Car)</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        <Button onClick={()=> this.setState({showAddBLCarDialog: true})} style={this.buttonStyle} type="primary">Add</Button>
        <Button onClick={ this.handleDelete } style={this.buttonStyle} type="danger">Delete</Button>
        <Button onClick={ this.handleEdit } style={this.buttonStyle} type="primary">Update</Button>
       
        <Table
          bordered
          style={{backgroundColor: '#FEFEFE'}}
          dataSource={this.state.blCarlist}
          columns={this.state.columns}
          rowSelection={userRowSelection}
          rowKey="id"
          pagination = {{total: this.state.total, pageSize: 1000, defaultCurrent: 1, onChange: this.changePage}}
        ></Table>
        <AddBLCar close={this.hideAddBLCarDialog} visible={this.state.showAddBLCarDialog}></AddBLCar>
        <EditBLCar data={this.state.editBLCarRow} close={this.hideEditBLCarDialog} visible={this.state.showEditBLCarDialog}></EditBLCar>
      </div>
    )
  }
}

export default BLCarMgr
import React, { Component } from 'react'
import { Form, Input, Icon, Upload, Button } from 'antd';
import { getLoginTocken } from '../../../Common/Auth';
class AddUnitFrm extends Component {
  handleChangeAvatar = (e) => {
    this.props.changeFileList(e.fileList);
    if(e.file.response) {
      console.log(e.file.response);
      return e.file.response.img;
    }
    return '';
  }
  render () {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form 
        layout="horizontal"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
      >
      {/* <Form.Item label="用户头像">
          {getFieldDecorator('avatar', {
            getValueFromEvent: this.handleChangeAvatar,
            rules: [
              {
                required: true,
                message: '请上传图片!',
              }
            ],
          })(
            <Upload
              accept="image/*"
              action="/per/upload"
              headers={{Authorization: getLoginTocken()}}
              name="imgF"
              listType="picture"
              onChange={this.handleChangeAvatar}
              fileList={this.props.fileList}
            >
              <Button>上传头像</Button>
            </Upload>
          )}
        </Form.Item> */}
        <Form.Item label="Block No.">
          {getFieldDecorator('blockNo', {
            rules: [
              {
                required: true,
                message: 'Please enter Block No.!',
              }
            ],
          })(
            <Input prefix={<Icon type="bank"></Icon>} 
              placeholder="Block No."
            />
          )}
        </Form.Item>
        <Form.Item label="Unit No.">
          {getFieldDecorator('unitNo', {
            rules: [
              {
                required: true,
                message: 'Please enter Unit No.!',
              }
            ],
          })(
            <Input prefix={<Icon type="book"></Icon>}
              placeholder="Unit No."
            />
          )}
        </Form.Item>
        <Form.Item label="Description">
          {getFieldDecorator('description', {
            rules: [
              {
                required: false,
                message: 'Please enter description!',
              }
            ],
          })(
            <Input prefix={<Icon type="edit"></Icon>} 
              placeholder="Description"
            />
          )}
        </Form.Item>
        <Form.Item label="Postal Code">
          {getFieldDecorator('postalCode', {
            rules: [
              {
                pattern: /\d{6}/gi,
                message: 'Please enter 6 digits Postal Code!',
              }
            ],
          })(
            <Input prefix={<Icon type="pushpin"></Icon>} 
              placeholder="Postal Code"
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

export default AddUnitFrm
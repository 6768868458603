import ActionTypes from './ActionTypes';
import service from '../Service';

export function LoadUnitAction(payload) {
  return {
    type: ActionTypes.LOAD_UNIT_LIST,
    payload
  }
}

// redux-thunk用法： 
export function LoadUnitActionAsync(params) {
  return dispatch => {
    return service.loadResidentUnits(params)
      .then(res => {
        console.log("mmmmm");
        console.log(params);
        console.log(res.data.resp_records);
        
        dispatch(LoadUnitAction({list: res.data.resp_records, total: parseInt(res.headers['x-total-count'])}));
      })
  }
}

export function LoadSuperAdminUserActionAsync(params) {
  return dispatch => {
    return service.loadSuperAdminUserSelf(params)
      .then(res => {
        console.log("mmmmm");
        console.log(params);
        console.log(res.data);
        console.log(res.data.resp_records);
        
        dispatch(LoadUnitAction({list: res.data.resp_records, total: parseInt(res.headers['x-total-count'])}));
      })
  }
}

export function AddUnitAction(payload) {
  console.log(payload);
  console.log("hahahha");
  return {
    type: ActionTypes.ADD_UNIT,
    payload
  }
}
export function AddUnitActionAsync(payload) {
  return dispatch => {
    return service.addResidentUnits(payload)
      .then(res => {
        console.log("AddUnitAction Resp:");
        console.log(res.data);
        
        
        dispatch(AddUnitAction(payload));
      })
  }
}

export function EditUnitActionAsync(payload) {
  return dispatch => {
    return service.updateResidentUnits(payload)
      .then(res => {
        dispatch(EditUnitAction(payload));
      })
  }
}

export function EditUnitAction(payload) {
  return {
    type: ActionTypes.EDIT_UNIT,
    payload
  }
}
import React, { Component } from 'react'
import { Form, Input, Icon } from 'antd';

class EditUserFrm extends Component {
  componentDidMount() {
    this.props.form.setFieldsValue({
      userName: this.props.data.userName,
      userEmail: this.props.data.userEmail,
      userPhone: this.props.data.userPhone,
      userPassword: this.props.data.userPassword
    });
  }
  render () {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form 
        layout="horizontal"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
      >
        {/* <Form.Item label="Email">
          {getFieldDecorator('userEmail', {
            rules: [
              {
                type: 'email',
                message: 'Please enter correct Email!',
              },
              {
                required: true,
                message: 'Please enter Email!',
              }
            ],
          })(
            <Input prefix={<Icon type="mail"></Icon>} 
              placeholder="Email"
            />
          )}
        </Form.Item> */}
        <Form.Item label="Password">
          {getFieldDecorator('userPassword', {
            rules: [
              {
                pattern: /\w{6,20}/gi,
                message: 'Please enter 6-20 chars!',
              },
              {
                required: true,
                message: 'Please enter password!',
              }
            ],
          })(
            <Input.Password prefix={
              <Icon type="safety" />
            } 
              placeholder="Password"
            />
          )}
        </Form.Item>
       
        <Form.Item label="User Name">
          {getFieldDecorator('userName', {
            rules: [
              {
                min: 2,
                message: 'Please enter more then 2 chars!',
              },
              {
                required: true,
                message: 'Please enter user name!',
              }
            ],
          })(
            <Input prefix={<Icon type="user"></Icon>} 
              placeholder="Full Name"
            />
          )}
        </Form.Item>
        <Form.Item label="Phone">
          {getFieldDecorator('userPhone', {
            rules: [
              {
                pattern: /\d{8}/gi,
                message: 'Please enter 8 digits phone number!',
              }
            ],
          })(
            <Input prefix={<Icon type="phone"></Icon>} 
              placeholder="Phone"
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

export default EditUserFrm

import React, { Component } from 'react'
import { Form, Input, Icon } from 'antd';

class EditBLCarFrm extends Component {
  componentDidMount() {
    this.props.form.setFieldsValue({
      blockNo: this.props.data.blockNo,
      unitNo: this.props.data.unitNo,
      description: this.props.data.description,
      postalCode: this.props.data.postalCode
    });
  }
  render () {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form 
        layout="horizontal"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
      >
        {/* <Form.Item label="Email">
          {getFieldDecorator('userEmail', {
            rules: [
              {
                type: 'email',
                message: 'Please enter correct Email!',
              },
              {
                required: true,
                message: 'Please enter Email!',
              }
            ],
          })(
            <Input prefix={<Icon type="mail"></Icon>} 
              placeholder="Email"
            />
          )}
        </Form.Item> */}
         {/* <Form.Item label="Car No.">
          {getFieldDecorator('carPlateNo', {
            rules: [
              {
                required: true,
                message: 'Please enter Car Plate No.!',
              }
            ],
          })(
            <Input prefix={<Icon type="bank"></Icon>} 
              placeholder="Car Plate No."
            />
          )}
        </Form.Item> */}
        <Form.Item label="Reason">
          {getFieldDecorator('reason', {
            rules: [
              {
                required: true,
                message: 'Please enter reason!',
              }
            ],
          })(
            <Input prefix={<Icon type="book"></Icon>}
              placeholder="Reason"
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

export default EditBLCarFrm

import ActionTypes from '../Action/ActionTypes';

export default function BLCarReducer(preState = {list:[], total: 0}, action) {
  switch(action.type) {
    case ActionTypes.LOAD_BLCAR_LIST: 
      return action.payload;
    case ActionTypes.ADD_BLCAR:
      preState.list.unshift(action.payload);
      preState.total+=1;
      return {...preState};
    case ActionTypes.EDIT_BLCAR: 
      let preBLCarIndex = preState.list.findIndex(item => item.id === action.payload.id);
      preState.list.splice(preBLCarIndex, 1, action.payload);
      return {...preState};
    default:
       return preState;
  }
}
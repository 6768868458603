import React, { Component } from 'react'
import { Menu, Icon } from 'antd';
const { SubMenu } = Menu;

const menuAll = [
    // {"id":"0" , "url": "/home/visitor_entry"}, //Live Visitors In & Out
    {"id":"1" , "url": "/home/live_visitor"}, //Live Visitors In & Out
    {"id":"2" , "url": "/home/guard_visitor"}, //Registered by Guard
    {"id":"3" , "url": "/home/goods_mgr"}, //Pre-Registered
    {"id":"4" , "url": "/home/block_unit"}, // Blocks & Units 
    {"id":"5" , "url": "/home/goods_mgr"}, //Residents
    {"id":"6" , "url": "/home/blacklist_car"}, // Black List Car Plate
    {"id":"7" , "url": "/home/atact_setting"}, //Setting
    {"id":"8" , "url": "/home/team_mgr"}, //Team Mgr
    {"id":"9" , "url": "/home/profile_mgr"} //Profile Mgr
];

class MenuAtact extends Component {
   
  state = {
    nothing: ''
  }
  componentDidMount() {
  }
  handleMenuClick = e => {
    // 控制路由跳转
    let url = menuAll.find(item => item.id == e.key).url;
    console.log(url)
    this.props.history.push(url);
  }
  render () {
    return (
      <div className="aside-menu-bar">
      <Menu onClick={this.handleMenuClick}  theme="dark"  mode="inline">
            {/* <Menu.Item key="0">
              <Icon type="scan" />
              <span>Visitor SafeEntry</span>
            </Menu.Item> */}
            
            <Menu.Item key="1">
              <Icon type="eye" />
              <span>Live Visitors In & Out</span>
            </Menu.Item>
            <Menu.Item key="2">
              <Icon type="database" />
              <span>Registered by Guard </span>
            </Menu.Item>
            <Menu.Item key="3">
              <Icon type="star" />
              <span>Pre-Registered </span>
            </Menu.Item>
            <Menu.Item key="4">
              <Icon type="bank" />
              <span>Blocks & Units </span>
            </Menu.Item>
            <Menu.Item key="5">
              <Icon type="user" />
              <span>Residents </span>
            </Menu.Item>
            <Menu.Item key="6">
              <Icon type="close" />
              <span>Black List (Car)</span>
            </Menu.Item>
            <Menu.Item key="7">
              <Icon type="setting" />
              <span>Setting</span>
            </Menu.Item>
            <Menu.Item key="8">
              <Icon type="team" />
              <span>Admin Users</span>
            </Menu.Item>
            <Menu.Item key="9">
              <Icon type="profile" />
              <span>Profile</span>
            </Menu.Item>
          </Menu>
      </div>
    )
  }
}

export default MenuAtact
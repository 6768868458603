import React, { Component } from 'react'
import { Form, Input, Icon, Upload, Button } from 'antd';
import { getLoginTocken } from '../../../Common/Auth';
class AddUserFrm extends Component {
  handleChangeAvatar = (e) => {
    this.props.changeFileList(e.fileList);
    if(e.file.response) {
      console.log(e.file.response);
      return e.file.response.img;
    }
    return '';
  }
  render () {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form 
        layout="horizontal"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
      >
      {/* <Form.Item label="用户头像">
          {getFieldDecorator('avatar', {
            getValueFromEvent: this.handleChangeAvatar,
            rules: [
              {
                required: true,
                message: '请上传图片!',
              }
            ],
          })(
            <Upload
              accept="image/*"
              action="/per/upload"
              headers={{Authorization: getLoginTocken()}}
              name="imgF"
              listType="picture"
              onChange={this.handleChangeAvatar}
              fileList={this.props.fileList}
            >
              <Button>上传头像</Button>
            </Upload>
          )}
        </Form.Item> */}
        <Form.Item label="Email">
          {getFieldDecorator('userEmail', {
            rules: [
              {
                type: 'email',
                message: 'Please enter correct email!',
              },
              {
                required: true,
                message: 'Please enter email!',
              }
            ],
          })(
            <Input prefix={<Icon type="mail"></Icon>} 
              placeholder="Email"
            />
          )}
        </Form.Item>
        <Form.Item label="Password">
          {getFieldDecorator('userPassword', {
            rules: [
              {
                pattern: /\w{6,20}/gi,
                message: 'Please enter 6-20 chars!',
              },
              {
                required: true,
                message: 'Please enter password!',
              }
            ],
          })(
            <Input.Password prefix={
              <Icon type="safety" />
            } 
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item label="User Name">
          {getFieldDecorator('userName', {
            rules: [
              {
                min: 2,
                message: 'Please enter more then 2 chars!',
              },
              {
                required: true,
                message: 'Please enter user name!',
              }
            ],
          })(
            <Input prefix={<Icon type="user"></Icon>} 
              placeholder="User Name"
            />
          )}
        </Form.Item>
        <Form.Item label="Phone">
          {getFieldDecorator('userPhone', {
            rules: [
              {
                pattern: /\d{8}/gi,
                message: 'Please enter 8 digits phone number!',
              }
            ],
          })(
            <Input prefix={<Icon type="phone"></Icon>} 
              placeholder="Phone"
            />
          )}
        </Form.Item>
      </Form>
    )
  }
}

export default AddUserFrm